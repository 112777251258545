import { jsx, jsxs, Fragment } from "react/jsx-runtime";
import { createState } from "@tecnobit-srl/state";
import axios from "axios";
import { isArray, map, isObject, mapValues, mapKeys, isString, camelCase, join, findKey } from "lodash-es";
import { useCallback, useMemo, useEffect } from "react";
const sanitizeData = (data) => {
  if (isArray(data)) {
    return map(data, (d) => sanitizeData(d));
  }
  if (isObject(data)) {
    return mapValues(
      mapKeys(
        data,
        (_, s) => isString(s) && s.startsWith("x_") ? camelCase(s.substring(2)) : s
      ),
      (d) => sanitizeData(d)
    );
  }
  if (isString(data)) {
    return data.startsWith("x_") ? camelCase(data.substring(2)) : data;
  }
  return data;
};
const { ContextProvider, useStore } = createState(
  "Router",
  (set, get) => ({
    loadingOverlay: false,
    eventListeners: { navigate: /* @__PURE__ */ new Set() },
    currentPage: null,
    setCurrentPage: (page) => {
      set((state) => ({ ...state, currentPage: page }));
    },
    on: (type, listener) => {
      set((state) => {
        state.eventListeners[type].add(listener);
        return { ...state };
      });
      return () => {
        set((state) => {
          state.eventListeners[type].delete(listener);
          return { ...state };
        });
      };
    },
    navigateTo: async (url, only, replace) => {
      set((state) => ({ ...state, loadingOverlay: true }));
      document.body.classList.toggle("loading", true);
      const { data } = await axios.get(
        url.includes("?") ? `${url}&inertia` : `${url}?inertia`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
            "X-Requested-With": "XMLHttpRequest",
            "X-Inertia": "true",
            ...only ? { "X-Inertia-Partial-Data": join(only, ",") } : {}
          }
        }
      );
      if (!data.props) {
        document.body.classList.toggle("loading", false);
        set((state) => ({ ...state, loadingOverlay: false }));
        throw new Error(`Invalid response from backend for page ${url}`);
      }
      const sanitizedData = sanitizeData(data.props);
      if (!replace) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
      const currentPage = only && only.length > 0 ? { ...get().currentPage ?? {}, ...sanitizedData } : sanitizedData;
      set((state) => ({ ...state, currentPage }));
      if (replace === true || only && only.length > 0) {
        window.history.replaceState(currentPage, "", url);
      } else {
        window.history.pushState(currentPage, "", url);
      }
      for (const listener of get().eventListeners.navigate) {
        listener(url, currentPage, only);
      }
      document.body.classList.toggle("loading", false);
      set((state) => ({ ...state, loadingOverlay: false }));
    },
    reload: async (only) => {
      var _a;
      const url = ((_a = get().currentPage) == null ? void 0 : _a.route.fullUrl) ?? window.location.href;
      if (!url) {
        return;
      }
      await get().navigateTo(url, only, true);
    },
    ssrRefresh: async () => {
      const ssrPage = get().currentPage;
      if (!ssrPage) {
        return;
      }
      const url = ssrPage.route.fullUrl;
      const { data } = await axios.get(
        url.includes("?") ? `${url}&inertia` : `${url}?inertia`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
            "X-Requested-With": "XMLHttpRequest",
            "X-Inertia": "true",
            "X-Inertia-Diff": ssrPage.hash
          }
        }
      );
      if (!data.props) {
        return;
      }
      const updatedPage = sanitizeData(data.props);
      set((state) => ({ ...state, currentPage: updatedPage }));
      window.history.replaceState(updatedPage, "", url);
      for (const listener of get().eventListeners.navigate) {
        listener(url, updatedPage);
      }
    }
  })
);
function InnerElement({
  children,
  loadingElement,
  initialPage
}) {
  const setCurrentPage = useStore((s) => s.setCurrentPage);
  const showLoadingOverlay = useStore((s) => s.loadingOverlay);
  useEffect(() => {
    window.history.replaceState(initialPage, "");
  }, [initialPage]);
  useEffect(() => {
    window.onpopstate = (event) => {
      if (event.state) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setCurrentPage(event.state);
      } else {
        return false;
      }
    };
    return () => {
      window.onpopstate = null;
    };
  }, [initialPage, setCurrentPage]);
  return /* @__PURE__ */ jsxs(Fragment, { children: [
    children,
    showLoadingOverlay ? loadingElement : null
  ] });
}
const usePage = () => {
  const page = useStore((s) => s.currentPage);
  return page;
};
const useNavigateTo = () => {
  const { ziggy } = usePage();
  const navigateTo = useStore((s) => s.navigateTo);
  return useCallback(
    async (url, only) => {
      if (isExternalUrl(ziggy, url) || url.startsWith("#")) {
        window.location.href = url;
        return;
      }
      return navigateTo(url, only);
    },
    [navigateTo, ziggy]
  );
};
const useReload = () => {
  return useStore((s) => s.reload);
};
const useSsrReload = () => {
  return useStore((s) => s.ssrRefresh);
};
const useAddRouterEventListener = () => {
  return useStore((s) => s.on);
};
function RouterProvider({
  children,
  loadingElement,
  currentPage
}) {
  const initialPage = useMemo(() => sanitizeData(currentPage), [currentPage]);
  return /* @__PURE__ */ jsx(ContextProvider, { currentPage: initialPage, children: /* @__PURE__ */ jsx(InnerElement, { initialPage, loadingElement, children }) });
}
const isExternalUrl = (ziggy, href) => {
  if (!href || href.startsWith("#") || href.startsWith("?") || href === "/") {
    return false;
  }
  const baseUrl = window.location.origin;
  let hrefSanitized = href;
  if (hrefSanitized.startsWith("http")) {
    if (!hrefSanitized.startsWith(baseUrl)) {
      return true;
    }
    hrefSanitized = hrefSanitized.substring(baseUrl.length);
  }
  try {
    hrefSanitized = new URL(
      `${baseUrl}/${hrefSanitized.replace(/^\/+/, "")}`
    ).pathname.replace(/(?:^\/+)|(?:\/+$)/, "");
  } catch {
    return true;
  }
  const foundRoute = findKey(
    ziggy.routes,
    (route) => route && (route.uri === hrefSanitized || route.uri.endsWith("/*") && hrefSanitized.startsWith(route.uri))
  );
  return !(foundRoute == null ? void 0 : foundRoute.startsWith("cms."));
};
export {
  RouterProvider,
  isExternalUrl,
  useAddRouterEventListener,
  useNavigateTo,
  usePage,
  useReload,
  useSsrReload
};
